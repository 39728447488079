import React, { useState, useEffect } from "react";
import { Select, Input, message, Spin, Button, DatePicker, Modal } from "antd";
import { http } from "../../../services/http";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";

function StockOmbor({ onClose, visible }) {
  const { t } = useTranslation();
  const todayDate = moment().format("DD/MM/YYYY");
  const [items, setItems] = useState([{ itemCode: "", quantity: "" }]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState({
    fdata: false,
  });

  const [data, setData] = useState({
    fdata: [],
    wdata: [],
    fromWarehouseCode: "",
    toWarehouseCode: "",
    fromWarehouseName:"",
    toWarehouseName:"",
  });

  const { fdata, wdata, fromWarehouseCode, toWarehouseCode,fromWarehouseName,toWarehouseName } = data;

  useEffect(() => {
    fetchAllData();

    if (visible) {
      resetForm();
    }
  }, [visible]);

  const fetchAllData = async () => {
    setLoadingData({ fdata: true });
    await Promise.all([fetchData(), fetchWarehouse()]);
    setLoadingData({ fdata: false });
  };

  const handleFromOmborChange = (value) => {
    const selectedItem = wdata.find((item) => item.name === value);
    setData((prevState) => ({
      ...prevState,
      fromWarehouseCode: selectedItem.code,
      fromWarehouseName:selectedItem.name,
    }));
  };

  const handleToOmborChange = (value) => {
    const selectedItem = wdata.find((item) => item.name === value);
    setData((prevState) => ({
      ...prevState,
      toWarehouseCode: selectedItem.code,
      toWarehouseName:selectedItem.name,
    }));
  };

  const fetchData = async () => {
    try {
      const { data } = await http.get(`api/items/ombordagiitems`);
      const mappedItems = data.map((item) => ({
        code: item.itemCode,
        name: item.itemName,
        quantityOnStock: item.quantityOnStock,
        quantityOrderedFromVendors: item.quantityOrderedFromVendors,
        quantityOrderedByCustomers: item.quantityOrderedByCustomers,
      }));
      setData((prevState) => ({
        ...prevState,
        fdata: mappedItems,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchWarehouse = async () => {
    try {
      const { data } = await http.get(`api/warehouses`);

      const filteredItems = data.filter(
        (item) =>
          item.warehouseCode !== "01"
      );

      const mappedItems = filteredItems.map((item) => ({
        code: item.warehouseCode,
        name: item.warehouseName,
      }));
      setData((prevState) => ({
        ...prevState,
        wdata: mappedItems,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectChange = (index, value) => {
    const selectedItem = fdata.find((item) => item.name === value);
    const newItems = [...items];
    newItems[index].itemCode = selectedItem.code;
    setItems(newItems);
  };

  const handleQuantityChange = (index, value) => {
    const newItems = [...items];
    newItems[index].quantity = value;
    setItems(newItems);
  };

  const addItem = () => {
    setItems([...items, { itemCode: "", quantity: "" }]);
  };

  const removeItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const postData = async () => {

    if (items.some((item) => !item.itemCode || !item.quantity)) {
      message.error(t("fillForm"));
      return;
    }

    setLoading(true);
    try {
      await http.post("api/inventorytransferrequest/stock-transfer", {
toWarehouse:toWarehouseCode,
        fromWarehouse:fromWarehouseCode,

        stockTransferLines: items.map((item, index) => ({
          ...item,
          warehouseCode: toWarehouseCode,
          fromWarehouseCode: fromWarehouseCode,
          lineNum: index,
        })),
      });
      message.success(t("sucCreated"));

      setTimeout(() => {
        resetForm();
        onClose();
      }, 800);
    } catch (error) {
      message.error(t("errCreation") + error.message);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setItems([{ itemCode: "", quantity: "" }]);
    setData((prevState) => ({
      ...prevState,
    }));
  };

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № 3
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="70%"
      style={{ height: "70%" }}
      bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
    >
      <div className="w-full px-4 sm:px-14">
        <div className="w-full">
          <div className="mt-10 flex w-full flex-col items-center gap-5 sm:flex-row">
            <div className="flex w-full flex-col items-center gap-5 sm:flex-row">
              <div className="flex w-full flex-col gap-3 sm:w-auto">
                <p>{t("from-whs")}</p>
                <Select
                  value={
                    fromWarehouseCode
                      ? wdata.find((item) => item.code === fromWarehouseCode)
                          ?.name
                      : undefined
                  }
                  placeholder={t("choose")}
                  showSearch
                  optionFilterProp="children"
                  onChange={handleFromOmborChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  className="flex h-12 w-full items-center justify-center sm:w-[250px]"
                >
                  {wdata
                    .filter((item) => item.name !== null)
                    .map((item) => (
                      <Select.Option key={item.code} value={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </div>

              <div className="flex w-full flex-col gap-3 sm:w-auto">
                <p>{t("toWhs")}</p>
                <Select
                  value={
                    toWarehouseCode
                      ? wdata.find((item) => item.code === toWarehouseCode)
                          ?.name
                      : undefined
                  }
                  placeholder={t("choose")}
                  showSearch
                  optionFilterProp="children"
                  onChange={handleToOmborChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  className="flex h-12 w-full items-center justify-center sm:w-[250px]"
                >
                  {wdata
                    .filter((item) => item.name !== null)
                    .map((item) => (
                      <Select.Option key={item.code} value={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </div>

              <div className="flex w-full flex-col gap-3 sm:w-auto">
                <p className="font-nunito">{t("date")}</p>
                <DatePicker
                  defaultValue={moment(todayDate, "DD/MM/YYYY")}
                  disabled
                  className="w-full sm:w-auto"
                />
              </div>
            </div>
          </div>
          <div className="mt-10 flex flex-col items-start gap-5 sm:flex-row sm:items-end">
            <div className="flex w-full flex-col sm:w-auto">
              {items.map((item, index) => (
                <div
                  key={index}
                  className="mt-1 flex flex-col items-center justify-between gap-3 sm:flex-row"
                >
                  <div className="flex w-full flex-col items-center gap-5 sm:flex-row">
                    <div className="flex w-full flex-col gap-3 sm:w-auto">
                      {index === 0 ? <p>{t("item")}</p> : ""}
                      <Spin spinning={loadingData.fdata}>
                        <Select
                          value={
                            item.itemCode
                              ? fdata.find((i) => i.code === item.itemCode)
                                  ?.name
                              : undefined
                          }
                          placeholder={t("choose")}
                          className="h-12 w-full items-center justify-center sm:w-[350px]"
                          onChange={(value) => handleSelectChange(index, value)}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {fdata
                            .filter((i) => i.name !== null)
                            .map((i) => (
                              <Select.Option key={i.code} value={i.name}>
                                {i.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Spin>
                    </div>

                    <div className="flex w-full flex-col gap-3 sm:w-auto">
                      {index === 0 ? <p>{t("quantity")}</p> : ""}
                      <Input
                        value={item.quantity}
                        onChange={(e) =>
                          handleQuantityChange(index, e.target.value)
                        }
                        type="text"
                        placeholder={t("quantity")}
                        className="h-12 w-full items-center justify-center border-[1px] border-[#D0D0D0] sm:w-[250px]"
                      />
                    </div>
                  </div>

                  {items.length > 1 && (
                    <button
                      onClick={() => removeItem(index)}
                      className={`mt-2 h-7 w-[30px] border-2 border-black bg-[#0A4D68] text-red-500 ${index === 0 ? "sm:mt-7" : "sm:mt-1"}`}
                    >
                      -
                    </button>
                  )}
                </div>
              ))}
            </div>

            <button
              onClick={addItem}
              className="mb-2 h-7 w-[30px] border-2 border-black bg-[#0A4D68] text-white"
            >
              +
            </button>
          </div>

          <div className="mt-24">
            <Button
              onClick={postData}
              className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[350px]"
              disabled={loading}
              loading={loading}
            >
              {loading ? t("saving") : t("saveAndCreate")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default StockOmbor;