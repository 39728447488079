import React, { useState, useCallback, useEffect } from "react";
import Menubar from "../../../components/WhrManager/App";
import { http } from "../../../services/http";
import RowDataOmborModal from "../../../components/WhrManager/row-data-ombor";
import { aggregateStockTransferLines } from "../../../utils/document";
import { useTranslation } from "react-i18next";
import Header from "../../../components/header";
import StockOmbor from "./StockOmbor";

const InProcess = () => {
  const { t } = useTranslation();
  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [isCreateProductModalVisible, setIsCreateProductModalVisible] =
    useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await http.get(
        `api/inventorytransferrequest/getconfirmed`,
      );

      const formattedData = aggregateStockTransferLines(data);
      setFData(formattedData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setIsModalVisible(true);
  };

  const handleCreateProductClick = () => {
    setIsCreateProductModalVisible(true);
  };

  const closeCreateProductModal = () => {
    setIsCreateProductModalVisible(false);
    fetchData();
  };

  const columns = [
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      // ...getColumnSearchProps("docEntry"),
    },

    // {
    //   title: t("quantity"),
    //   dataIndex: "sum",
    //   key: "sum",
    //   render: (text) => {
    //     const formattedText = new Intl.NumberFormat("fr-FR").format(text);
    //     return <span>{formattedText}</span>;
    //   },
    //   // ...getColumnSearchProps("quantity"),
    // },
    {
      title: t("from-whs"),
      dataIndex: "fromWarehouseName",
      key: "fromWarehouseName",
      // ...getColumnSearchProps("fromWarehouseCode"),
    },
    {
      title: t("toWhs"),
      dataIndex: "warehouseName",
      key: "warehouseName",
      // ...getColumnSearchProps("warehouseCode"),
    },
  ];

  const paginatedData = fdata.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );
  const hasMoreData = fdata.length > currentPage * pageSize;

  return (
    <div className="flex w-full">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <Header
          title={"accepted"}
          currentPage={currentPage}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          hasMoreData={hasMoreData}
          onBtnClick={handleCreateProductClick}
          columns={columns}
          fdata={paginatedData}
          loading={loading}
          handleRowClick={handleRowClick}
        />
        <RowDataOmborModal
          visible={isModalVisible}
          data={selectedRowData}
          onClose={() => setIsModalVisible(false)}
        />
        <StockOmbor
          visible={isCreateProductModalVisible}
          onClose={closeCreateProductModal}
        />
      </div>
    </div>
  );
};

export default InProcess;
